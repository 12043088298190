




































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import TopBar from '@/components/organisms/o-top-bar.vue'
import DateField from '@/components/base/DateField.vue'
import TimeField from '@/components/base/TimeField.vue'
import SelectTrainingLocation from '@/components/molecules/select-search/location.vue'
import VehiclesTableList from '@/components/molecules/logisticSession/vehiclesTableList.vue'
import TeachingToolsTableList from '@/components/molecules/logisticSession/teachingToolsTableList.vue'
import TrainingSessionForm from '@/components/molecules/logisticSession/trainingSession.vue'
import NewTrainingSessionBtn from '@/components/molecules/logisticSession/newTrainingSessionBtn.vue'
import HousingTrainer from '@/components/molecules/logisticSession/housingTrainer.vue'
import TrainingMeals from '@/components/molecules/logisticSession/trainingMeals.vue'
import ListLogisticSessionLogger from '@/components/molecules/logisticSession/listLogisticSessionLogger.vue'
import SelectTrainers from '@/components/molecules/select-search/trainers.vue'
import BaseMaterialCard from '@/components/base/MaterialCard.vue'
import CancelUpdateDialog from '@/components/cancel-update-dialog.vue'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { RawLocation } from 'vue-router'
import MDialogDocument from '@/components/molecules/downloadDocumentDialog/m-dialog-document.vue'
import store from '@/store'
import { useDate } from '@/composables/date'
import { isReadonly, userConnectedHasRole } from '@/composables/UserGrant'
import { LogisticSession, LogisticSessionDay, TrainingOfficerCompany } from '@/api/interfaces/logisticSession'
import { getHours, getMinutes } from 'date-fns'
import SelectTrainingOfficer from '@/components/molecules/select/trainingOfficer.vue'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
import { User } from '@/api/interfaces/user'
import { UserApplication } from '@/api/interfaces/userApplication'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { driverProgram } from '@/config-constantes'
import { Company } from '@/api/interfaces/company'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    const userIsRegionManager = () => userConnectedHasRole('ROLE_TRAINER_REGION_MANAGER')
    const canWriteTrainingSession = () => userConnectedHasRole('ROLE_WRITE_TRAINING_SESSION')

    return {
      readonly,
      userIsRegionManager,
      canWriteTrainingSession,
    }
  },
  name: 'LogisticSession',
  components: {
    SelectSearchTrainingType,
    TopBar,
    DateField,
    TimeField,
    SelectTrainingLocation,
    VehiclesTableList,
    TeachingToolsTableList,
    TrainingSessionForm,
    HousingTrainer,
    TrainingMeals,
    ListLogisticSessionLogger,
    SelectTrainers,
    BaseMaterialCard,
    MDialogDocument,
    NewTrainingSessionBtn,
    CancelUpdateDialog,
    SelectTrainingOfficer,
    SelectCompanies: () =>
      import('@/components/molecules/select-search/company.vue'),
  },
  data () {
    return {
      isLoading: true,
      dialogDocument: false,
      minHoursStartAt: '',
      maxHoursEndAt: '',
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('logisticSessionForm', [
      'logisticSession.id',
      'logisticSession.startAt',
      'logisticSession.endAt',
      'logisticSession.technicalPreparation',
      'logisticSession.trainers',
      'logisticSession.commentary',
      'logisticSession.trainingSessions',
      'logisticSession.trainingLocation',
      'logisticSession.nameHousing',
      'logisticSession.addressHousing',
      'logisticSession.cityHousing',
      'logisticSession.zipCodeHousing',
      'logisticSession.priceHousing',
      'logisticSession.infoHousing',
      'logisticSession.vehicles',
      'logisticSession.commentaryVehicle',
      'logisticSession.teachingTools',
      'logisticSession.commentaryMaterials',
      'logisticSession.caterer',
      'logisticSession.logisticSessionsDay',
      'logisticSession.trainingOfficer',
      'logisticSession.supplierRelationshipOfficer',
      'logisticSession.secondaryTrainingOfficers',
    ]),
    ...mapState('logisticSessionForm', {
      isFormChanged: 'isChanged',
      logisticSession: 'logisticSession',
      isLogisticSessionSubmitted: 'isSubmitted',
    }),
    ...mapGetters('navigation', {
      returnPreviousRoute: 'getReturnPreviousRoute',
      getLastRouteNeedTraining: 'getLastRouteNeedTraining',
    }),
    ...mapState('trainingOfficer', {
      listTrainingOfficersDriverProgram: 'listTrainingOfficersDriverProgram',
      listTrainingOfficersOthers: 'listTrainingOfficersOthers',
      listOfficersSupplierRelationship: 'listOfficersSupplierRelationship',
      loadingTrainingOfficersDriverProgram: 'loadingTrainingOfficersDriverProgram',
      loadingTrainingOfficersOthers: 'loadingTrainingOfficersOthers',
      loadingOfficersSupplierRelationship: 'loadingOfficersSupplierRelationship',
    }),
    idLogisticSession (): string | null {
      return this.$route.params.idLogisticSession || null
    },
    trainingTypesSelected (): Array<TrainingType> {
      const trainingTypes: Array<TrainingType> = [];

      (this.trainingSessions as Array<TrainingSession>).map((trainingSession: TrainingSession) => {
        const alreadyIncluded = trainingTypes.filter((trainingType: TrainingType) => {
          if (trainingSession.trainingType) {
            const iriTrainingSessionTrainingType = typeof trainingSession.trainingType === 'object' ? trainingSession.trainingType['@id'] : trainingSession.trainingType
            const iriTrainingType = typeof trainingType === 'object' ? trainingType['@id'] : trainingType
            if (iriTrainingType === iriTrainingSessionTrainingType) {
              return trainingType
            }
          }
        }).length > 0

        if (trainingSession.trainingType && !alreadyIncluded) {
          trainingTypes.push(trainingSession.trainingType as TrainingType)
        }
      })
      return trainingTypes
    },
    trainingTypeIsOnMultipleDays (): boolean {
      return (this.logisticSessionsDay as Array<LogisticSessionDay>).length > 0
    },
    listTrainingOfficersDriverProgramFiltered (): Array<User> {
      let resultA = 0
      let resultB = 0

      return (this.listTrainingOfficersDriverProgram as Array<User>).sort((a: User, b: User) => {
        (this.trainingSessions as Array<TrainingSession>).forEach((trainingSession: TrainingSession) => {
          if (trainingSession.needTrainings.length > 0) {
            trainingSession.needTrainings.some((needTraining) => {
              if (needTraining.company && needTraining.company.trainingOfficersOthers) {
                needTraining.company.trainingOfficersDriverProgram.some((iriTrainingOfficer: string) => {
                  if (iriTrainingOfficer === (a.userApplication as UserApplication)['@id']) {
                    resultA = -1
                    return true
                  }
                  if (iriTrainingOfficer === (b.userApplication as UserApplication)['@id']) {
                    resultB = -1
                    return true
                  }
                  return false
                })
              }
            })
          }
        })

        return resultA - resultB
      })
    },
    listTrainingOfficersOthersFiltered (): Array<User> {
      let resultA = 0
      let resultB = 0

      return (this.listTrainingOfficersOthers as Array<User>).sort((a: User, b: User) => {
        (this.trainingSessions as Array<TrainingSession>).forEach((trainingSession: TrainingSession) => {
          if (trainingSession.needTrainings.length > 0) {
            trainingSession.needTrainings.some((needTraining) => {
              if (needTraining.company && needTraining.company.trainingOfficersOthers) {
                needTraining.company.trainingOfficersOthers.some((iriTrainingOfficer: string) => {
                  if (iriTrainingOfficer === (a.userApplication as UserApplication)['@id']) {
                    resultA = -1
                    return true
                  }
                  if (iriTrainingOfficer === (b.userApplication as UserApplication)['@id']) {
                    resultB = -1
                    return true
                  }
                  return false
                })
              }
            })
          }
        })

        return resultA - resultB
      })
    },
    canAddSecondaryTrainingOfficer () {
      return (this.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).every((secondaryTrainingOfficer: TrainingOfficerCompany) => {
        return secondaryTrainingOfficer.trainingOfficer && secondaryTrainingOfficer.company
      })
    },
    isTrainingTypeDriverProgram () {
      return (this.trainingTypesSelected as Array<TrainingType>)[0].code === driverProgram
    },
  },
  async created () {
    await this.init()
  },
  methods: {
    ...mapActions('user', { sendSuccessMessage: 'sendSuccessMessage' }),
    ...mapActions('logisticSessionForm', {
      loadLogisticSession: 'loadById',
      save: 'save',
      resetLogisticSession: 'reset',
    }),
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    ...mapMutations('logisticSessionForm', {
      addNewTrainingSession: 'addNewTrainingSession',
      setIsFormChanged: 'setIsChanged',
    }),
    ...mapActions('trainingOfficer', {
      loadTrainingOfficersDriverProgram: 'loadTrainingOfficersDriverProgram',
      loadTrainingOfficersOthers: 'loadTrainingOfficersOthers',
      loadOfficersSupplierRelationship: 'loadOfficersSupplierRelationship',
      loadCommercials: 'loadCommercials',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver) // eslint-disable-line @typescript-eslint/no-explicit-any
      if (isValidatedForm) {
        await this.save()
        this.sendSuccessMessage('logistic_session.form.saved')
        const route: RawLocation = (this
          .returnPreviousRoute as RawLocation) || {
          name: 'TrainingSession List',
        }
        // cancel dialog update if saving
        this.forceCancelSaveNextRoute(route)
        this.$router.push(route)
      }
    },
    returnPrevious () {
      if (this.returnPreviousRoute) {
        this.$router.push(this.returnPreviousRoute as RawLocation)
      } else {
        this.$router.push({ name: 'TrainingSession List' })
      }
    },
    async init () {
      if (
        this.idLogisticSession &&
        (
          !(this.logisticSession as LogisticSession)['@id'] ||
          (this.logisticSession && this.idLogisticSession !== ((this.logisticSession as LogisticSession).id as number).toString())
        )
      ) {
        this.isLoading = true
        await Promise.all([
          this.loadLogisticSession(this.idLogisticSession),
          this.loadTrainingOfficersDriverProgram({ pagination: false }),
          this.loadTrainingOfficersOthers({ pagination: false }),
          this.loadOfficersSupplierRelationship({ pagination: false }),
          this.loadCommercials({ pagination: false }),
        ])
      } else {
        await Promise.all([
          this.loadTrainingOfficersDriverProgram({ pagination: false }),
          this.loadTrainingOfficersOthers({ pagination: false }),
          this.loadOfficersSupplierRelationship({ pagination: false }),
          this.loadCommercials({ pagination: false }),
        ])
      }
      if (!this.isTrainingTypeDriverProgram) {
        this.addMissingTrainingOfficersCompanyInList()
      }
      this.isLoading = false
    },
    changeHours (date: string, dateNewHours: string): string {
      const newTime = new Date(dateNewHours)
      return useDate().createFormatISO(date, getHours(newTime), getMinutes(newTime))
    },
    addSecondaryTrainingOfficer () {
      (this.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).push({
        trainingOfficer: null,
        company: null,
      })
    },
    removeSecondaryTrainingOfficer (item: any) {
      const index = (this.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).indexOf(item);
      (this.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).splice(index, 1)
    },
    addMissingTrainingOfficersCompanyInList () {
      (this.trainingSessions as Array<TrainingSession>).forEach((trainingSession: TrainingSession) => {
        trainingSession.needTrainings.forEach((needTraining: NeedTraining) => {
          if (needTraining.company) {
            if (
              !(this.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).some((secondaryTrainingOfficer: TrainingOfficerCompany) => {
                return (secondaryTrainingOfficer.company as Company)['@id'] === needTraining.company['@id']
              })
            ) {
              (this.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).push({
                trainingOfficer: needTraining.company?.trainingOfficersOthers?.length > 0 ? needTraining.company.trainingOfficersOthers[0] : null,
                company: needTraining.company,
              })
            }
          }
        })
      })
    },
  },
  watch: {
    idLogisticSession () {
      this.init()
    },
    logisticSession: {
      handler (newValue, oldValue) {
        if (oldValue && oldValue['@id']) {
          this.setIsFormChanged(true)
        }
      },
      deep: true,
    },
    'logisticSession.trainingSessions': {
      handler (newValue: Array<TrainingSession>) {
        this.maxHoursEndAt = ''
        this.minHoursStartAt = ''
        newValue.forEach((trainingSession: TrainingSession) => {
          // console.log('watch logisticSession.trainingSessions', trainingSession.startAt, trainingSession.endAt)
          // console.log('watch logisticSession.trainingSessions setMin?', (!this.minHoursStartAt) ||
          //  (trainingSession.startAt && this.minHoursStartAt > trainingSession.startAt), this.minHoursStartAt, trainingSession.startAt)
          if (
            (!this.minHoursStartAt) ||
            (trainingSession.startAt && this.minHoursStartAt > trainingSession.startAt)
          ) {
            this.minHoursStartAt = trainingSession.startAt as string
          }

          // console.log('watch logisticSession.trainingSessions setMax?', (!this.maxHoursEndAt) ||
          //  (trainingSession.endAt && this.maxHoursEndAt < trainingSession.endAt), this.maxHoursEndAt, trainingSession.endAt)
          if (
            (!this.maxHoursEndAt) ||
            (trainingSession.endAt && this.maxHoursEndAt < trainingSession.endAt)
          ) {
            this.maxHoursEndAt = trainingSession.endAt as string
          }
        })
        // console.log('watch logisticSession.trainingSessions min/max', this.minHoursStartAt, this.maxHoursEndAt)

        const changeHours = (this as any).changeHours // eslint-disable-line @typescript-eslint/no-explicit-any
        if ((this.startAt as string) > this.minHoursStartAt) {
          // console.log('watch logisticSession.trainingSessions set startAt', this.startAt, changeHours(this.startAt as string, this.minHoursStartAt as string))
          this.startAt = changeHours(this.startAt as string, this.minHoursStartAt as string)
        }

        if ((this.endAt as string) < this.maxHoursEndAt) {
          // console.log('watch logisticSession.trainingSessions set endAt', this.endAt, changeHours(this.endAt as string, this.maxHoursEndAt as string))
          this.endAt = changeHours(this.endAt as string, this.maxHoursEndAt as string)
        }
      },
      deep: true,
    },
    'logisticSession.startAt': {
      handler (newValue: string, oldValue: string) {
        if (this.trainingTypeIsOnMultipleDays && (this.logisticSessionsDay as Array<LogisticSessionDay>)[0].id !== this.id) {
          return
        }

        const changeHours = (this as any).changeHours // eslint-disable-line @typescript-eslint/no-explicit-any
        // console.log('watch logisticSession.startAt', newValue, oldValue, this.startAt)
        if (!this.endAt && newValue) {
          // console.log('watch logisticSession.startAt set endAT ', this.endAt, changeHours(newValue, this.maxHoursEndAt as string))
          this.endAt = changeHours(newValue, this.maxHoursEndAt as string)
        }
        if (newValue !== oldValue && oldValue !== undefined) {
          if (newValue) {
            // console.log('watch logisticSession.startAt set endAT ', this.endAt, changeHours(newValue, this.endAt as string))
            this.endAt = changeHours(newValue, this.endAt as string)
          }
          const ts = this.trainingSessions as TrainingSession[]
          ts.forEach((trainingSession: TrainingSession) => {
            // console.log('watch logisticSession.startAt set session.startAt ', trainingSession.startAt, changeHours(newValue, trainingSession.startAt as string))
            trainingSession.startAt = changeHours(newValue, trainingSession.startAt as string)
            // console.log('watch logisticSession.startAt set session.endAT ', trainingSession.endAt, changeHours(newValue, trainingSession.endAt as string))
            trainingSession.endAt = changeHours(newValue, trainingSession.endAt as string)
          })
        }
      },
    },
  },
  beforeRouteUpdate (to, _from, next) {
    store.commit('navigation/removeRoute')
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteEnter (to, _from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, _from, next) {
    if (
      to.name !== 'Trainee List Associate TrainingSession' &&
      to.name !== 'Vehicle List Associate LogisticSession' &&
      to.name !== 'TeachingTool List Associate LogisticSession'
    ) {
      this.resetLogisticSession()
      store.commit('navigation/removeRoute')
    }
    next()
  },
})
