















































































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import BaseMaterialCard from '@/components/base/MaterialCard.vue'
import TrainingMeals from '@/components/molecules/logisticSession/trainingMeals.vue'

export default defineComponent({
  name: 'HousingTrainer',
  components: {
    TopBar,
    BaseMaterialCard,
    TrainingMeals,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      model: this.value,
    }
  },
  methods: {},
})
